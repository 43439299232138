<template>
  <div >
    <div class="flex-center ">
      <div class="code">
        404
      </div>

      <div class="message">
        Not Found
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.code {
  border-right: 2px solid;
  font-size: 26px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #636b6f;
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
}
.message {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  color: #636b6f;
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}
</style>
